//
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background: linear-gradient(120deg, #8e2de2, #4a00e0, #dd2476);
    background-size: cover;
    background-position: center;
}

// Logout page
.logout-icon {
    width: 140px;
}

.auth-logo {
    .logo-light {
        display: $logo-auth-light-display !important;
    }
    .logo-dark {
        display: $logo-auth-dark-display !important;
    }
}
