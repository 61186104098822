@each $color,
$value in $theme-colors {
    .bg-#{$color} {
        background-color: $value !important;
    }
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
    .text-#{$color} {
        color: $value !important;
    }
}

.bg-ghost {
    opacity: 0.4;
}
